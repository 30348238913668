<template>
  <v-app>
      <FormCustom :fields="fields"></FormCustom>
  </v-app>
</template>

<script>


import FormCustom from "./FormCustom";
export default {
  name: 'App',
  components: { FormCustom },
  data() {
    return {
      fields: [
          {
            name: 'FormField',
            id: 1,
            config: {
              cols: 12,
              placeholder: 'seleziona una azienda',
              label: 'Azienda',
              required: true,
              rules: [v => v && !!v.trim() || 'Azienda obbligatoria'],
              customsEvents:
                {
                  'click': a => { console.log('click Azienda'); console.log(a); },
                  'focus': a => { console.log('focus Azienda'); console.log(a); },
                  'change': a => console.log('change Azienda ' + a),
                  'input': a => console.log('input Azienda ' + a)
                }
            }
          },
          {
            name: 'FormField',
            id: 2,
            config: {
              cols: 6,
              placeholder: 'seleziona un magazzino',
              label: 'Magazzino',
              required: true,
              rules: [v => v && v.trim() !== 'winet' || 'Magazzino deve essere diverso winet'],
              customsEvents:
                {
                  'click': a => { console.log('click Magazzino'); console.log(a);},
                  'focus': a => { console.log('focus Magazzino'); console.log(a); },
                  'change': a => console.log('change Magazzino ' + a)
                },
            }
          },
          // esempio di come utilizzare FormCheckBox dentro CompanyForm (se preso e aggiunto con cambio id etc.. funzionante)
          {
              name: 'FormCheckBox',
              id: 3,
              config: {
                  key: 'prova',
                  cols: 6,
                  label: 'Prova Booleana',
                  change: a => {console.log("scelto"); console.log(a);},
                  isDisabled: a => a.postal_code === '50125'
              }
          }
      ]
    };
  }
};
</script>
